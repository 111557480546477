

@font-face {
  font-family: "AvenirLT-Oblique";
  src: url("./fonts/33A665_0_0.eot");
  src: url("./fonts/33A665_0_0.eot?#iefix") format("embedded-opentype"),
    url("./fonts/33A665_0_0.woff2") format("woff2"),
    url("./fonts/33A665_0_0.woff") format("woff"),
    url("./fonts/33A665_0_0.ttf") format("truetype");
}

@font-face {
  font-family: "AvenirLT-HeavyOblique";
  src: url("./fonts/33A665_1_0.eot");
  src: url("./fonts/33A665_1_0.eot?#iefix") format("embedded-opentype"),
    url("./fonts/33A665_1_0.woff2") format("woff2"),
    url("./fonts/33A665_1_0.woff") format("woff"),
    url("./fonts/33A665_1_0.ttf") format("truetype");
}

@font-face {
  font-family: "AvenirLT-Heavy";
  src: url("./fonts/33A665_2_0.eot");
  src: url("./fonts/33A665_2_0.eot?#iefix") format("embedded-opentype"),
    url("./fonts/33A665_2_0.woff2") format("woff2"),
    url("./fonts/33A665_2_0.woff") format("woff"),
    url("./fonts/33A665_2_0.ttf") format("truetype");
}

@font-face {
  font-family: "AvenirLT-Light";
  src: url("./fonts/33A665_3_0.eot");
  src: url("./fonts/33A665_3_0.eot?#iefix") format("embedded-opentype"),
    url("./fonts/33A665_3_0.woff2") format("woff2"),
    url("./fonts/33A665_3_0.woff") format("woff"),
    url("./fonts/33A665_3_0.ttf") format("truetype");
}

@font-face {
  font-family: "AvenirLT-LightOblique";
  src: url("./fonts/33A665_4_0.eot");
  src: url("./fonts/33A665_4_0.eot?#iefix") format("embedded-opentype"),
    url("./fonts/33A665_4_0.woff2") format("woff2"),
    url("./fonts/33A665_4_0.woff") format("woff"),
    url("./fonts/33A665_4_0.ttf") format("truetype");
}

@font-face {
  font-family: "AvenirLT-Bold";
  src: url("./fonts/340B71_1_0.eot");
  src: url("./fonts/340B71_1_0.eot?#iefix") format("embedded-opentype"),
    url("./fonts/340B71_1_0.woff2") format("woff2"),
    url("./fonts/340B71_1_0.woff") format("woff"),
    url("./fonts/340B71_1_0.ttf") format("truetype");
}

* {
  font-family: "AvenirLT-Light";
}

html, body{
  /* background-image: radial-gradient(#e3e3e3 5%, transparent 5%);
  background-color: white;
  background-position: 0 0, 50px 50px;
  background-size: 30px 30px; */
  background: transparent;
}

#app {
  filter: blur(5px);
}

canvas {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: white;
  width: 100% !important;
  height: 100% !important;
}


.icon {
  font-size: 28px;
}

.App {
  text-align: center;
  position: relative;
  overflow: hidden;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

.form {
  margin: 0 auto;
  padding: 100px 30px 0;
}
@media (min-width: 800px) {
  .form {
    max-width: 60%;
  }
}

.input-range {
  margin-bottom: 20px;
}

.carousel-wrapper,
.carousel,
.carousel > .slider-wrapper,
.carousel > .slider-wrapper > .slider {
  height: 100%;
}

.drawer > div {
  height: 100%;
}


.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}


select {
  background:url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='50px' height='50px'><polyline points='46.139,15.518 25.166,36.49 4.193,15.519'/></svg>");
  background-color:#999999;
  background-repeat:no-repeat;
  background-position: right 10px center;
  background-size: 16px 16px;
  color:white;
  padding:12px;
  padding-right: 30px;
  margin-right: 10px;
  width:auto;
  font-family:arial,tahoma;
  font-size:16px;
  text-align: left;
  color:#000000;
  border-radius:0px;
  -webkit-border-radius:0;
  -webkit-appearance: none;
  -moz-appearance: none;
  border:0;
  outline:0;
  -webkit-transition:0.3s ease all;
	   -moz-transition:0.3s ease all;
	    -ms-transition:0.3s ease all;
	     -o-transition:0.3s ease all;
	        transition:0.3s ease all;
	text-overflow: '';
	-moz-appearance: none;
	text-indent: 0.01px;
}

select::-ms-expand {
    display: none;
}

#blue {
  background-color:#b4b4b4;
}


select:focus, select:active {
  border:0;
  outline:0;
}

option:focus
{
  background: pink;
}

