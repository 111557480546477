@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?i7amnh');
  src:  url('fonts/icomoon.eot?i7amnh#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?i7amnh') format('truetype'),
    url('fonts/icomoon.woff?i7amnh') format('woff'),
    url('fonts/icomoon.svg?i7amnh#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 1.5rem;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-centered:before {
  content: "\e938";
}
.icon-cloud:before {
  content: "\e939";
}
.icon-fill1:before {
  content: "\e93a";
}
.icon-fill2:before {
  content: "\e93b";
}
.icon-fill3:before {
  content: "\e93c";
}
.icon-justified:before {
  content: "\e93d";
}
.icon-leftaligned:before {
  content: "\e93e";
}
.icon-rightaligned:before {
  content: "\e93f";
}
.icon-spiral:before {
  content: "\e940";
}
.icon-squarefill1:before {
  content: "\e941";
}
.icon-squarefill2:before {
  content: "\e942";
}
.icon-squarefill3:before {
  content: "\e943";
}
.icon-symbol:before {
  content: "\e944";
}
.icon-trash:before {
  content: "\e945";
}
.icon-typesize:before {
  content: "\e946";
}
.icon-activity:before {
  content: "\e900";
}
.icon-alarm:before {
  content: "\e901";
}
.icon-bubble:before {
  content: "\e902";
}
.icon-cancel:before {
  content: "\e903";
}
.icon-circle:before {
  content: "\e904";
}
.icon-crop:before {
  content: "\e905";
}
.icon-donotdisturb:before {
  content: "\e906";
}
.icon-down:before {
  content: "\e907";
}
.icon-draw:before {
  content: "\e908";
}
.icon-experimental:before {
  content: "\e909";
}
.icon-facebook:before {
  content: "\e90a";
}
.icon-favourite:before {
  content: "\e90b";
}
.icon-freesketch:before {
  content: "\e90c";
}
.icon-info:before {
  content: "\e90d";
}
.icon-instagram:before {
  content: "\e90e";
}
.icon-joto:before {
  content: "\e90f";
}
.icon-keyboard:before {
  content: "\e910";
}
.icon-laptop:before {
  content: "\e911";
}
.icon-left:before {
  content: "\e912";
}
.icon-like:before {
  content: "\e913";
}
.icon-line:before {
  content: "\e914";
}
.icon-list:before {
  content: "\e915";
}
.icon-lock:before {
  content: "\e916";
}
.icon-login:before {
  content: "\e917";
}
.icon-logout:before {
  content: "\e918";
}
.icon-menu:before {
  content: "\e919";
}
.icon-message:before {
  content: "\e91a";
}
.icon-move:before {
  content: "\e91b";
}
.icon-OK:before {
  content: "\e91c";
}
.icon-picture:before {
  content: "\e91d";
}
.icon-power-switch:before {
  content: "\e91e";
}
.icon-pulse:before {
  content: "\e91f";
}
.icon-redo:before {
  content: "\e920";
}
.icon-right:before {
  content: "\e921";
}
.icon-search:before {
  content: "\e922";
}
.icon-see:before {
  content: "\e923";
}
.icon-settings:before {
  content: "\e924";
}
.icon-shapes:before {
  content: "\e925";
}
.icon-share:before {
  content: "\e926";
}
.icon-sketch:before {
  content: "\e927";
}
.icon-square:before {
  content: "\e928";
}
.icon-star:before {
  content: "\e929";
}
.icon-stickers:before {
  content: "\e92a";
}
.icon-sync:before {
  content: "\e92b";
}
.icon-tag:before {
  content: "\e92c";
}
.icon-text:before {
  content: "\e92d";
}
.icon-text2:before {
  content: "\e92e";
}
.icon-twitter:before {
  content: "\e92f";
}
.icon-undo:before {
  content: "\e930";
}
.icon-up:before {
  content: "\e931";
}
.icon-upload:before {
  content: "\e932";
}
.icon-user:before {
  content: "\e933";
}
.icon-warning:before {
  content: "\e934";
}
.icon-wavyline:before {
  content: "\e935";
}
.icon-wifi:before {
  content: "\e936";
}
.icon-wifioff:before {
  content: "\e937";
}
